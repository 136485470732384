<template>
    <div
        class="st-day-col"
        :class="[
            {
                'bg-holiday': isHoliday,
                'bg-today': isToday,
            },
            `sty-${type}`,
        ]"
        :style="{ width: `${width}%` }"
        @click="confirmWarn(rosterDaysId, dateOfMonth)"
    >
        <div
            class="st-day-wrapper"
            :class="[
                {
                    'border-top-0': !rowIdx,
                    'border-start-0': colIdx,
                },
            ]"
        >
            <!-- 星期幾 -->
            <template v-if="type === 'subject'">
                <span class="st-date-txt">{{ dateOfMonth }}</span>
                <span class="st-day-txt">{{ dayOfWeek }}</span>
            </template>
            <!-- 芳療師使用 -->
            <span
                class="st-day-symbol"
                v-if="holidayType || isMoved || supportStoreId"
                :class="[holidayClass]"
            >
                {{ extractedTypeOfLeave }}
            </span>
        </div>
        <div
            class="st-extension"
            v-if="type === 'subject'"
            :class="[
                {
                    'border-top-0': !rowIdx,
                    'border-start-0': colIdx,
                },
            ]"
        ></div>
    </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import SwalCustom from "@/hooks/common/useSwalCustom";
import Swal from "sweetalert2";

import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";

export default {
    name: "StDayCol",
    props: {
        type: {
            type: String,
            default: "subject",
        },
        // 假別
        typeOfLeave: {
            type: String,
            default: "",
        },
        date: {
            type: String,
            required: true,
        },
        rowIdx: {
            type: Number,
            default: 0,
        },
        colIdx: {
            type: Number,
            default: 0,
        },
        // 榮譽假: 1  半天榮譽: 2  例假日: 3  休息日: 4  請假: 5 曠職: 9 培訓期: 10
        holidayType: {
            type: [Number, String],
            default: 0,
        },
        width: {
            type: Number,
            default: 0,
        },
        rosterDaysId: {
            type: [Number, String],
            default: 0,
        },
        memberId: {
            type: [Number, String],
            default: 0,
        },
        isMoved: [Number, String],
        isMoveFrom: [Number, String],
        beforeMoveHoliday: String,
        arrangeId: [Number, String],
        shiftList: Array,
        supportStoreId: Number,
        memberName: String,
    },
    setup({
        type,
        date,
        typeOfLeave,
        holidayType,
        memberId,
        isMoved,
        isMoveFrom,
        beforeMoveHoliday,
        arrangeId,
        shiftList,
        supportStoreId,
        memberName
    }) {
        const { state, commit, dispatch } = useStore();
        const DAY_ARR = ["日", "一", "二", "三", "四", "五", "六"];
        const DAY = moment(date).day();
        const dateOfMonth = ref(moment(date).date());
        const dayOfWeek = computed(() => {
            return DAY_ARR[DAY];
        });
        const extractedTypeOfLeave = computed(() => {
            if (isMoved) {
                // return shiftList.find(item => item.id === arrangeId).name;
                return "原";
            } else if (isMoveFrom) {
                return "挪";
            }

            return typeOfLeave.split("")[0];
        });

        
        // 是否為六日
        const isHoliday = computed(() => {
            return DAY === 0 || DAY === 6;
        });
        // 是否為當天
        const isToday = computed(() => {
            return moment(date).isSame(moment(), "day");
        });

        const HOLIDAY_LIST = ["榮譽假", "半天榮譽", "例假日", "休息日", "請假", "曠職", "培訓期"];
        const holidayClass = computed(() => {
            if (holidayType) {
                return `holiday-${holidayType}`;
            } 
            else if (isMoved) {
                const idx = HOLIDAY_LIST.indexOf(beforeMoveHoliday);
                return `holiday-${idx + 1}`;
            } 
            else if (supportStoreId) {
                return `holiday-support`;
            }

            return "";
        });

        const { openShiftLightbox } = useHandleLightboxOpen();
        const newSwal = new SwalCustom({
            title: "需要先選擇班別",
            icon: "warning",
            confirmButtonText: "確定",
        });
        const memberType = computed(() => state.auth.member_type);

        // 是否是過去時間
        const isBeforeToday = computed(() => {
            return moment(date).isBefore(moment(), 'day');
        });

        const momentDate = moment(date);
        const confirmWarn = (id, date) => {
            if (isToday.value) {
                Swal.fire({
                    title: '當天排假需於芳療師下班打卡前修改確認',
                    icon: 'warning',
                    confirmButtonText: '確定',
                }).then(() => {
                    handleOpenShiftLightbox(id, date);
                });
                return;
            } else if(isBeforeToday.value) {
                Swal.fire({
                    title: `${momentDate.month() + 1}月${momentDate.date()}日排假確認後無法取消，是否要繼續進行排假`,
                    icon: 'warning',
                    confirmButtonText: '確定',
                    showCancelButton: true,
                    cancelButtonText: "取消",
                }).then((result) => {
                    if (result.value) {
                        handleOpenShiftLightbox(id, date);
                    }
                });
                return;
            }
            handleOpenShiftLightbox(id, date);
        };

        const handleOpenShiftLightbox = async (id, day) => {
            // 如果是星期幾 顯示用就跳出
            if(type === 'subject') return;
            // 若非主管就不能編輯排假
            if (memberType.value !== 3) return;

            if (!id) {
                newSwal.fire();
                return;
            }

            const { store_id } = state.auth;

            commit("schedule/update", {
                key: "shiftMemberInfo",
                value: {
                    name: memberName,
                    date: moment(date).format("YYYY/MM/DD"),
                },
            });

            await dispatch("schedule/getLeaveInfo", {
                store_id,
                roster_days_id: id,
                memberId,
                date: day,
            });

            await dispatch("schedule/getAllowedChangeDates", {
                store_id,
                member_id: memberId,
            });

            openShiftLightbox();
        };

        return {
            dateOfMonth,
            dayOfWeek,
            extractedTypeOfLeave,
            isHoliday,
            holidayClass,
            isToday,
            confirmWarn,
        };
    },
};
</script>

<style lang="scss">
@import "~@/css/mixins";

.st-day-col {
    box-sizing: border-box;

    &.bg-today {
        .st-day-wrapper,
        .st-extension {
            position: relative;
            background-color: rgba(217, 67, 67, 0.75);

            &::after {
                content: "";
                @include size(3px, calc(100% + 1px));
                background-color: rgba(217, 67, 67, 1);
                position: absolute;
                right: -1.5px;
                top: -1px;
            }
        }
    }

    &.sty-subject,
    &.sty-hole {
        &.bg-holiday {
            .st-day-wrapper,
            .st-extension {
                background-color: rgba(143, 149, 164, 0.1);
            }
        }

        &.bg-official {
            .st-day-wrapper,
            .st-extension {
                background-color: rgba(164, 100, 26, 0.15);
            }
        }

        .st-day-wrapper,
        .st-extension {
            border: 1px solid rgba(214, 214, 214, 1);
            border-top: none;
            background-color: rgba(245, 245, 245, 1);
            position: relative;
        }
    }

    &.sty-subject {
        &.bg-holiday .st-day-wrapper {
            background-color: rgba(15, 42, 104, 0.55);
        }

        &.bg-official .st-day-wrapper {
            background-color: rgba(164, 100, 26, 0.7);
        }

        &.bg-today .st-day-wrapper {
            position: relative;
            background-color: rgba(217, 67, 67, 0.75);

            &::before {
                content: "";
                @include size(16px);
                border-radius: 50%;
                background: rgba(217, 67, 67, 1);
                position: absolute;
                left: 100%;
                bottom: 100%;
                transform: translateX(-50%);
            }
        }

        .st-day-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 12px 9px 17px 9px;
            background-color: #8f95a4;
            color: #ffffff;
        }

        .st-date-txt {
            margin-bottom: 9px;
            font-size: 12px;
            line-height: 17px;
        }

        .st-day-txt {
            font-size: 14px;
            line-height: 19px;
        }

        .st-extension {
            @include size(100%, 30px);
            border-bottom: none;

            &::before {
                content: "";
                @include size(1px, 100%);
                border-left: 1px dashed rgba(162, 162, 162, 0.5);
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
            }
        }
    }

    &.sty-hole {
        .st-day-wrapper {
            @include size(100%, 48px);

            &::before {
                content: "";
                @include size(1px, 100%);
                border-left: 1px dashed rgba(162, 162, 162, 0.5);
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
            }
        }

        .st-day-symbol {
            min-width: 18px;
            min-height: 18px;
            padding: 5px;
            border: 1px solid #fff;
            border-radius: 50%;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            box-sizing: border-box;
            font-size: 16px;
            color: #fff;
            @include pos-center;

            &.holiday-1 {
                background-color: rgba(183, 127, 26, 1);
            }

            &.holiday-2 {
                background-color: rgba(109, 69, 69, 1);
            }

            &.holiday-3 {
                background-color: rgba(0, 121, 242, 1);
            }

            &.holiday-4 {
                background-color: rgba(5, 170, 102, 1);
            }

            &.holiday-5 {
                background-color: rgba(255, 65, 111, 1);
            }

            &.holiday-support {
                background-color: #fff;
            }
            // 曠職
            &.holiday-9 {
                background-color: rgba(0, 0, 0, 1);
            }
            // 培訓期
            &.holiday-10 {
                background-color: rgba(255, 174, 21, 1);
                display: inline-flex;
                &:before{
                    content: '培';
                    font-size: 12px;
                }
            }
            // 婚假
            &.holiday-11 {
                background-color: #fb9ff8;
            }
            // 喪假
            &.holiday-12,
            &.holiday-13,
            &.holiday-14 {
                background-color: #a2a2a2;
            }
        }
    }

    .st-day-wrapper,
    .st-extension {
        box-sizing: border-box;
        transition: all 0.3s ease;
    }
}
</style>
