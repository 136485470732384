<template>
    <ShiftLightbox />
    <div class="schedule">
        <div class="top">
            <Logo />
        </div>
        <div class="index-container">
            <div class="title">芳療師假表</div>

            <div class="content-panel">
                <StContainer />
            </div>
        </div>
    </div>
    <FixedOrderInfo/>
</template>

<script>
import Logo from "@/components/includes/Logo";
import ShiftLightbox from "@/components/lightbox/ShiftLightbox";
import StContainer from "@/components/schedule/StContainer";
import FixedOrderInfo from '@/components/inline/FixedOrderInfo';

export default {
    name: "Schedule",
    components: {
        Logo,
        StContainer,
        ShiftLightbox,
        FixedOrderInfo
    },
};
</script>

<style lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.schedule {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            @include phone {
                margin-bottom: 20px;
            }
        }
    }

    .content-panel {
        padding: 28px 22px 46px 33px;
        border: 1px solid #fff;
        border-radius: 15px;
        box-shadow: 0 34px 77px 0 rgba(0, 0, 0, 0.26);
        background: linear-gradient(
            143deg,
            #fff,
            #f1eeee, 
            #fff
        );
        @media (max-width: 1200px) {
            padding: 20px;
        }
        overflow: overlay;
    }
}
</style>
