<template>
    <div class="st-row st-aroma-sec">
        <div class="st-subject-sec">
            <span class="st-name-txt">{{ scheduleObj.member_name }}</span>
            <span class="st-position-txt">{{ scheduleObj.ranks_name }}</span>
            <div
                class="st-toggler-bar"
                :class="{ active: attendanceStatus }"
                @click="toggleAttendance(attendanceStatus)"
            >
                全勤
            </div>
            <select class="st-selector" v-model="shiftType">
                <option value="0" selected disabled>班別</option>
                <option
                    v-for="shift in shiftList"
                    :key="shift.id"
                    :value="shift.id"
                >
                    {{ shift.name }}
                </option>
            </select>
        </div>
        <div class="st-day-sec">
            <StDayCol
                v-for="(obj, idx) in dateShiftArr"
                :key="new Date().getTime() + idx"
                type="hole"
                :date="`${year}-${month}-${obj.days}`"
                :holidayType="obj.leave_type"
                :typeOfLeave="obj.arrangeStr"
                :rowIdx="rowIdx"
                :colIdx="idx"
                :width="100 / dateShiftArr.length"
                :rosterDaysId="obj.roster_days_id"
                :memberId="scheduleObj.member_id"
                :isMoved="obj.toster_day_move_id"
                :isMoveFrom="obj.toster_day_source_id_date"
                :beforeMoveHoliday="obj.note"
                :arrangeId="scheduleObj.arrange_id"
                :shiftList="shiftList"
                :supportStoreId="obj.support_store_id"
                :memberName="scheduleObj.member_name"
            />
        </div>
    </div>
</template>

<script>
import { computed, ref, watch, toRefs } from "vue";
import { useStore } from "vuex";
import { debounce } from "lodash";
import { basePost } from "@/js/services/baseService";
import SwalCustom from "@/hooks/common/useSwalCustom";
import StDayCol from "@/components/schedule/StDayCol";

export default {
    name: "StAromaSec",
    components: {
        StDayCol,
    },
    props: {
        rowIdx: {
            type: Number,
            default: 0,
        },
        scheduleObj: {
            type: Object,
            default: null,
        },
    },
    setup(props) {
        const { state, getters, dispatch } = useStore();
        const { scheduleObj } = toRefs(props);
        const shiftList = computed(() => state.schedule.shiftList);
        const year = computed(() => Number(getters["schedule/getYear"]));
        const month = computed(() => Number(getters["schedule/getMonth"]));
        const totalDays = computed(() => Number(getters["schedule/getDays"]));
        const dateShiftArr = computed(() => {
            const { days } = props.scheduleObj;

            if (days.length) return days;

            const arr = [];
            for (let i = 0; i < totalDays.value; i++) {
                arr.push({
                    arrangeStr: "",
                    days: i + 1,
                    leave_type: "",
                    roster_days_id: null,
                });
            }

            return arr;
        });
        const attendanceStatus = ref("");
        const shiftType = ref(0);
        let canShiftTypeEdit = true;
        const customSwal = new SwalCustom({
            title: "錯誤!",
            text: "",
            icon: "error",
            confirmButtonText: "確定",
        });

        // 切換全勤狀態
        const toggleAttendance = debounce(async (status) => {
            const newStatus = status ? 0 : 1;
            try {
                const res = await basePost(
                    "/api_roster/change_attendance_check",
                    {
                        store_id: state.auth.store_id,
                        roster_month_id: scheduleObj.value.roster_month_id,
                        attendance: newStatus,
                    }
                );
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    attendanceStatus.value = newStatus;
                } else {
                    customSwal.fire({
                        text: "不能更換全勤狀態",
                    });
                }
            } catch (err) {
                customSwal.fire({
                    text: "需要先選擇班別",
                    icon: "warning",
                });
                console.error(err);
            }
        }, 300);
        watch(
            scheduleObj,
            (obj) => {
                const { attendance, arrange_id } = obj;
                attendanceStatus.value = attendance;
                shiftType.value = arrange_id || 0;
                canShiftTypeEdit = false;

                setTimeout(() => {
                    canShiftTypeEdit = true;
                }, 300);
            },
            {
                immediate: true,
            }
        );

        // 切換班別
        const toggleShiftType = async (newVal, oldVal) => {
            // 判斷是否可以編輯，若是從該函式觸發的watch就不能繼續做以下的動作
            if (!canShiftTypeEdit) {
                canShiftTypeEdit = true;
                return;
            }

            const fallbackStatus = () => {
                shiftType.value = oldVal;
                canShiftTypeEdit = false;
            };

            try {
                const res = await basePost("/api_roster/roster_check", {
                    store_id: state.auth.store_id,
                    member_id: scheduleObj.value.member_id,
                    arrange_id: newVal,
                    year: year.value,
                    month: month.value,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    // 重新請求當前日期的假表清單
                    dispatch("schedule/getScheduleList", {
                        store_id: state.auth.store_id,
                        year: getters["schedule/getYear"],
                        month: Number(getters["schedule/getMonth"]),
                    });
                } else {
                    fallbackStatus();
                    customSwal.fire({
                        text: "不能更換班別",
                    });
                }
            } catch (err) {
                fallbackStatus();
                customSwal.fire({
                    text: err.message,
                });
                console.error(err);
            }
        };
        watch(shiftType, (newVal, oldVal) => {
            toggleShiftType(newVal, oldVal);
            canShiftTypeEdit = true;
        });

        return {
            shiftList,
            year,
            month,
            dateShiftArr,
            attendanceStatus,
            toggleAttendance,
            shiftType,
            scheduleObj,
        };
    },
};
</script>

<style lang="scss">
@import "~@/css/mixins";

.st-aroma-sec {
    &.st-row {
        display: flex;
        transition: all 0.3s ease;
        position: relative;

        &:hover {
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            z-index: 1;

            .st-subject-sec,
            .st-day-wrapper {
                background-color: rgba(214, 214, 214, 0.8);
            }
        }
    }

    .st-subject-sec {
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid rgba(214, 214, 214, 1);
        transition: background-color 0.3s ease;
        box-sizing: border-box;
        width: 320px;
        flex-shrink: 0;

        span {
            display: inline-block;
        }

        .st-position-txt {
            font-weight: 600;
            color: rgba(143, 149, 164, 1);
        }

        .st-index-txt {
            font-size: 14px;
        }

        .st-name-txt {
            margin-right: 5px;
            word-break: keep-all;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 1);
            @include text-ellipsis;
            max-width: 100px;
            width: 100%;
        }

        .st-position-txt {
            font-size: 16px;
            word-break: keep-all;
            @include text-ellipsis;
            max-width: 70px;
            width: 100%;
        }   

        // toggler
        .st-toggler-bar {
            font-size: 15px;
            flex-shrink: 0;
            margin: 0 5px;
            padding: 5px 5px;
            border-radius: 13px;
            box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.17);
            background: #fff;
            cursor: pointer;
            &.active {
                color: #fff;
                background: linear-gradient(
                    180deg,
                    rgba(235, 196, 150, 1),
                    rgba(164, 100, 26, 1),
                    rgba(181, 120, 49, 1),
                    rgba(164, 100, 26, 1)
                );
            }
        }

        // selector
        .st-selector {
            height: 100%;
            max-width: 60px;
            padding: 0 15px 0 0;
            border: none;
            font-size: 16px;
            background-image: url("~@/assets/tgd/icon/triangle.svg");
            background-repeat: no-repeat;
            background-position: 100% calc((100% / 2) + 2px);
            background-color: transparent;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
        }
    }

    .st-day-sec {
        display: flex;
        width: calc(100% - 320px);
    }
}
</style>
